@import "./colors.scss";


// SWITCH
.switch {
    display: flex;
    align-items: center;

    .title {
        font-size: 14px;

        &.left {
            margin-right: 8px;
        }

        &.right {
            margin-left: 8px;
        }
    }

    /* The switch - the box around the slider */
    label {
        position: relative;
        display: inline-block;
        width: 54px;
        height: 28px;

        /* Hide default HTML checkbox */
        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $green-200;
            }

            &:focus + .slider {
                //border: 1px solid $green-300;
            }

            &:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            /* Rounded sliders */
            &.round {
                border-radius: 34px;
            }

            &.round:before {
                border-radius: 50%;
            }
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
}
