@import '~bootstrap/scss/bootstrap.scss';
@import "~@vp/design-transformation-ui-library/styles/flat-ui.scss";

.resultTabsButton {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
}

mark {
  cursor: pointer;
}

.col-form-label {
  font-size: 0.8em;
}

.typeahed-badge {
  min-width: 100px;
  text-align: left;
}
