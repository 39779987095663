/* White */

$pure-white: #ffffff;

$white-50: #fefefe;
$white-100: #fdfdfd;
$white-200: #fcfcfc;
$white-300: #fbfbfb;
$white-400: #fafafa;
$white-500: #f9f9f9;
$white-600: #f8f8f8;
$white-700: #f7f7f7;
$white-800: #f6f6f6;
$white-900: #f5f5f5;

$lighter-white: $white-200;
$white: $white-500;
$darker-white: $white-900;

/* Black */

$pure-black: #000000;

$black-50: #202020;
$black-100: #1d1d1d;
$black-200: #1a1a1a;
$black-300: #171717;
$black-400: #141414;
$black-500: #111111;
$black-600: #0e0e0e;
$black-700: #0b0b0b;
$black-800: #080808;
$black-900: #050505;

$lighter-black: $black-200;
$black: $black-500;
$darker-black: $black-900;

/* Red */

$red-50: #ffebee;
$red-100: #ffcdd2;
$red-200: #ef9a9a;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;

$lighter-red: $red-200;
$red: $red-500;
$darker-red: $red-900;

/* Pink */

$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;

$lighter-pink: $pink-200;
$pink: $pink-500;
$darker-pink: $pink-900;

/* Purple */

$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;

$lighter-purple: $purple-200;
$purple: $purple-500;
$darker-purple: $purple-900;

/* Deep Purple */

$deepPurple-50: #ede7f6;
$deepPurple-100: #d1c4e9;
$deepPurple-200: #b39ddb;
$deepPurple-300: #9575cd;
$deepPurple-400: #7e57c2;
$deepPurple-500: #673ab7;
$deepPurple-600: #5e35b1;
$deepPurple-700: #512da8;
$deepPurple-800: #4527a0;
$deepPurple-900: #311b92;

$lighter-deepPurple: $deepPurple-200;
$deepPurple: $deepPurple-500;
$darker-deepPurple: $deepPurple-900;

/* Indigo */

$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;

$lighter-indigo: $indigo-200;
$indigo: $indigo-500;
$darker-indigo: $indigo-900;

/* Blue */

$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;

$lighter-blue: $blue-200;
$blue: $blue-500;
$darker-blue: $blue-900;


/* Light Blue */ 

$lightBlue-50: #e1f5fe;
$lightBlue-100: #b3e5fc;
$lightBlue-200: #81d4fa;
$lightBlue-300: #4fc3f7;
$lightBlue-400: #29b6f6;
$lightBlue-500: #03a9f4;
$lightBlue-600: #039be5;
$lightBlue-700: #0288d1;
$lightBlue-800: #0277bd;
$lightBlue-900: #01579b;

$lighter-lightBlue: $lightBlue-200;
$lightBlue: $lightBlue-500;
$darker-lightBlue: $lightBlue-900;

/* Cyan */ 

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;

$lighter-cyan: $cyan-200;
$cyan: $cyan-500;
$darker-cyan: $cyan-900;

/* Teal */

$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;

$lighter-teal: $teal-200;
$teal: $teal-500;
$darker-teal: $teal-900;

/* Green */

$green-50: #e8f5e9;
$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;

$lighter-green: $green-200;
$green: $green-500;
$darker-green: $green-900;

/* Light Green */

$lightGreen-50: #f1f8e9;
$lightGreen-100: #dcedc8;
$lightGreen-200: #c5e1a5;
$lightGreen-300: #aed581;
$lightGreen-400: #9ccc65;
$lightGreen-500: #8bc34a;
$lightGreen-600: #7cb342;
$lightGreen-700: #689f38;
$lightGreen-800: #558b2f;
$lightGreen-900: #33691e;

$lighter-lightGreen: $lightGreen-200;
$lightGreen: $lightGreen-500;
$darker-lightGreen: $lightGreen-900;

/* Lime */

$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;

$lighter-lime: $lime-200;
$lime: $lime-500;
$darker-lime: $lime-900;

/* Yellow */

$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;

$lighter-yellow: $yellow-200;
$yellow: $yellow-500;
$darker-yellow: $yellow-900;

/* Amber */

$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;

$lighter-amber: $amber-200;
$amber: $amber-500;
$darker-amber: $amber-900;

/* Orange */

$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;

$lighter-orange: $orange-200;
$orange: $orange-500;
$darker-orange: $orange-900;

/* Deep Orange */

$deepOrange-50: #fbe9e7;
$deepOrange-100: #ffccbc;
$deepOrange-200: #ffab91;
$deepOrange-300: #ff8a65;
$deepOrange-400: #ff7043;
$deepOrange-500: #ff5722;
$deepOrange-600: #f4511e;
$deepOrange-700: #e64a19;
$deepOrange-800: #d84315;
$deepOrange-900: #bf360c;

$lighter-deepOrange: $deepOrange-200;
$deepOrange: $deepOrange-500;
$darker-deepOrange: $deepOrange-900;

/* Brown */

$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;

$lighter-brown: $brown-200;
$brown: $brown-500;
$darker-brown: $brown-900;

/* Grey */

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$lighter-grey: $grey-200;
$grey: $grey-500;
$darker-grey: $grey-900;

/* Blue Grey */

$blueGrey-50: #eceff1;
$blueGrey-100: #cfd8dc;
$blueGrey-200: #b0bec5;
$blueGrey-300: #90a4ae;
$blueGrey-400: #78909c;
$blueGrey-500: #607d8b;
$blueGrey-600: #546e7a;
$blueGrey-700: #455a64;
$blueGrey-800: #37474f;
$blueGrey-900: #263238;

$lighter-blueGrey: $blueGrey-200;
$blueGrey: $blueGrey-500;
$darker-blueGrey: $blueGrey-900;

/* Gradient */

.gradient_10{ color: #4ca91f; }
.gradient_9 { color: #71b72f; }
.gradient_8 { color: #91c53f; }
.gradient_7 { color: #b0d351; }
.gradient_6 { color: #c4cc49; }
.gradient_5 { color: #d6c546; }
.gradient_4 { color: #e6be48; }
.gradient_3 { color: #eca03d; }
.gradient_2 { color: #ed803e; }
.gradient_1 { color: #e96047; }
.gradient_0 { color: #dd3e54; }